h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

.font-normal {
  font-weight: var(--font-weight-normal);
}

.font-medium {
  font-weight: var(--font-weight-medium);
}

.font-semi-bold {
  font-weight: var(--font-weight-semibold);
}

.font-bold {
  font-weight: var(--font-weight-bold);
}

.font-extra-bold {
  font-weight: var(--font-weight-extrabold);
}

.text-xs {
  font-size: var(--font-size-text-xs);
  line-height: var(--line-height-text-xs);
}
.text-sm {
  font-size: var(--font-size-text-sm);
  line-height: var(--line-height-text-sm);
}
.text-base {
  font-size: var(--font-size-text-base);
  line-height: var(--line-height-text-base);
}
.text-lg {
  font-size: var(--font-size-text-lg);
  line-height: var(--line-height-text-lg);
}
.text-xl {
  font-size: var(--font-size-text-xl);
  line-height: var(--line-height-text-xl);
}
.text-2xl {
  font-size: var(--font-size-text-2xl);
  line-height: var(--line-height-text-2xl);
}
.text-3xl {
  font-size: var(--font-size-text-3xl);
  line-height: var(--line-height-text-3xl);
}
.text-4xl {
  font-size: var(--font-size-text-4xl);
  line-height: var(--line-height-text-4xl);
}
.text-5xl {
  font-size: var(--font-size-text-5xl);
  line-height: var(--line-height-text-5xl);
}
.text-6xl {
  font-size: var(--font-size-text-6xl);
  line-height: var(--line-height-text-6xl);
}
.text-7xl {
  font-size: var(--font-size-text-7xl);
  line-height: var(--line-height-text-7xl);
}
.text-8xl {
  font-size: var(--font-size-text-8xl);
  line-height: var(--line-height-text-8xl);
}
.text-9xl {
  font-size: var(--font-size-text-9xl);
  line-height: var(--line-height-text-9xl);
}
