.AppLoader {
  position: fixed;
  inset: 0;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.AppLoaderSpinner {
  opacity: 0;
  animation: fadein 0.25s ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 3s;

  svg {
    color: var(--color-secondary-300);
    display: block;
    max-width: 60px;
    margin: 0 auto;
    margin-bottom: var(--spacing-2);
  }

  span {
    font-size: var(--font-size-text-sm);
  }
}

.AppLoaderError {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-4);
  color: var(--color-red-400);

  svg {
    display: block;
    margin: 0 auto;
    height: 60px;
    width: 60px;
    margin-bottom: var(--spacing-2);
  }

  span {
    font-size: var(--font-size-text-sm);
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
