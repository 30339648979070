.toast {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background: var(--color-white);
  padding: var(--spacing-4);
  width: 360px;
  gap: var(--spacing-3);
  box-shadow:
    0 3px 10px rgba(0, 0, 0, 0.1),
    0 3px 3px rgba(0, 0, 0, 0.05);
  border-radius: 0 8px 8px 0;
  font-family: var(--font-sans);

  border-left: 4px solid;
  &[data-state="success"] {
    border-left-color: var(--color-success);

    .toast__title,
    .toast__closeButton {
      color: var(--color-success);
    }
  }

  &[data-state="error"] {
    border-left-color: #ff5630;

    .toast__title,
    .toast__closeButton {
      color: #ff5630;
    }
  }

  &[data-state="warning"] {
    border-left-color: #ffa600;

    .toast__title,
    .toast__closeButton {
      color: #ffa600;
    }
  }

  &[data-state="info"] {
    border-left-color: #377dff;

    .toast__title,
    .toast__closeButton {
      color: #377dff;
    }
  }
}

.toast__body {
  margin-left: var(--spacing-1);
  flex-grow: 1;
}

.toast__header {
  display: flex;
  justify-content: space-between;
}

.toast__title {
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-text-md);
}

.toast__content {
  color: var(--color-neutral-800);
  margin-top: var(--spacing-1);
}

.toast__closeButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  cursor: pointer;
  border: none;
  padding: 0;
  margin-top: var(--spacing-2);
}
