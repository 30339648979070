@import "./reset.css";
@import "./typography.css";

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --font-sans: "Baloo 2", sans-serif;

  --font-size-text-xs: 0.75rem;
  --font-size-text-sm: 0.875rem;
  --font-size-text-md: 0.9735rem;
  --font-size-text-base: 1rem;
  --font-size-text-lg: 1.125rem;
  --font-size-text-xl: 1.25rem;
  --font-size-text-2xl: 1.5rem;
  --font-size-text-3xl: 1.875rem;
  --font-size-text-4xl: 2.25rem;
  --font-size-text-5xl: 3rem;
  --font-size-text-6xl: 3.75rem;
  --font-size-text-7xl: 4.5rem;
  --font-size-text-8xl: 6rem;
  --font-size-text-9xl: 8rem;

  --line-height-text-xs: 1rem;
  --line-height-text-sm: 1.25rem;
  --line-height-text-base: 1.5rem;
  --line-height-text-md: 1.5rem;
  --line-height-text-lg: 1.75rem;
  --line-height-text-xl: 1.75rem;
  --line-height-text-2xl: 2rem;
  --line-height-text-3xl: 2.25rem;
  --line-height-text-4xl: 2.5rem;
  --line-height-text-5xl: 1;
  --line-height-text-6xl: 1;
  --line-height-text-7xl: 1;
  --line-height-text-8xl: 1;
  --line-height-text-9xl: 1;

  --font-weight-thin: 100;
  --font-weight-extralight: 200;
  --font-weight-light: 300;
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;
  --font-weight-extrabold: 800;
  --font-weight-black: 900;

  --color-white: #ffffff;
  --color-success: #38cb89;
  --color-success-dark: #4db848;
  --color-success-bg: #eaf7f4;
  --color-disabled: #a0a0a0;
  --color-background: #f5f6fa;
  --color-warning: #fef9f0;
  --color-warning-bg: #ffb33e;
  --color-focus: #0026ff;
  --color-focus-react-select: #2684ff;
  --color-info-bg: #ebf2ff;
  --color-info: #72a2ff;

  --color-primary-50: #fdefe0;
  --color-primary-100: #fad6b3;
  --color-primary-200: #f7bb80;
  --color-primary-300: #f39f4d;
  --color-primary-400: #f18b26;
  --color-primary-500: #ee7600;
  --color-primary-600: #ec6e00;
  --color-primary-700: #d66a00;
  --color-primary-800: #e75900;
  --color-primary-900: #e24600;

  --color-primary-contrast-50: #333333;
  --color-primary-contrast-100: #333333;
  --color-primary-contrast-200: #333333;
  --color-primary-contrast-300: #333333;
  --color-primary-contrast-400: #333333;
  --color-primary-contrast-500: #333333; /* ARIA contrast 4.34 */
  --color-primary-contrast-600: #333333; /* ARIA contrast 4.08 */
  --color-primary-contrast-700: #ffffff;
  --color-primary-contrast-800: #ffffff;
  --color-primary-contrast-900: #ffffff;

  --color-secondary-50: #e7efff;
  --color-secondary-100: #c3d8ff;
  --color-secondary-200: #9bbeff;
  --color-secondary-300: #73a4ff;
  --color-secondary-400: #5591ff;
  --color-secondary-500: #377dff;
  --color-secondary-600: #3175ff;
  --color-secondary-700: #2a6aff;
  --color-secondary-800: #2360ff;
  --color-secondary-900: #164dff;

  --color-secondary-contrast-50: #333333;
  --color-secondary-contrast-100: #333333;
  --color-secondary-contrast-200: #333333;
  --color-secondary-contrast-300: #333333;
  --color-secondary-contrast-400: #333333; /* ARIA 4.13 */
  --color-secondary-contrast-500: #ffffff;
  --color-secondary-contrast-600: #ffffff;
  --color-secondary-contrast-700: #ffffff;
  --color-secondary-contrast-800: #ffffff;
  --color-secondary-contrast-900: #ffffff;

  --color-neutral-50: #ffffff;
  --color-neutral-100: #eeeeee;
  --color-neutral-200: #e3e3e3;
  --color-neutral-300: #d1d1d1;
  --color-neutral-400: #acacac;
  --color-neutral-500: #8b8b8b;
  --color-neutral-600: #646464;
  --color-neutral-700: #515151;
  --color-neutral-800: #333333;
  --color-neutral-900: #131313;

  --color-neutral-contrast-50: #333333;
  --color-neutral-contrast-100: #333333;
  --color-neutral-contrast-200: #333333;
  --color-neutral-contrast-300: #333333;
  --color-neutral-contrast-400: #333333;
  --color-neutral-contrast-500: #cccccc;
  --color-neutral-contrast-600: #ffffff;
  --color-neutral-contrast-700: #ffffff;
  --color-neutral-contrast-800: #ffffff;
  --color-neutral-contrast-900: #ffffff;

  --color-red-50: #fde7e0;
  --color-red-100: #fac4b3;
  --color-red-200: #f79d80;
  --color-red-300: #f3754d;
  --color-red-400: #f15826;
  --color-red-500: #ee3a00;
  --color-red-600: #ec3400;
  --color-red-700: #e92c00;
  --color-red-800: #e72500;
  --color-red-900: #e21800;

  --color-red-contrast-50: #333333; /* Contrast ratio 10.63 */
  --color-red-contrast-100: #333333; /* Contrast ratio 8.18 */
  --color-red-contrast-200: #333333; /* Contrast ratio 6.07 */
  --color-red-contrast-300: #333333; /* Contrast ratio 4.48 */
  --color-red-contrast-400: #333333; /* Contrast ratio 3.7 (3.4 with white) */
  --color-red-contrast-500: #ffffff; /* Contrast ratio 4 */
  --color-red-contrast-600: #ffffff; /* Contrast ratio 4.15 */
  --color-red-contrast-700: #ffffff; /* Contrast ratio 4.35 */
  --color-red-contrast-800: #ffffff; /* Contrast ratio 4.5 */
  --color-red-contrast-900: #ffffff; /* Contrast ratio 4.81 */
  --color-skeleton-bg: hsla(227, 35%, 92%, 1);
  --color-disabled-bg: #f2f2f2;
  --color-disabled-border: #e6e6e6;

  --border-radius-sm: 0.125rem;
  --border-radius-base: 0.25rem;
  --border-radius-md: 0.375rem;
  --border-radius-lg: 0.5rem;
  --border-radius-xl: 0.75rem;
  --border-radius-2xl: 1rem;
  --border-radius-3xl: 1.5rem;
  --border-radius-6xl: 3rem;
  --border-radius-full: 9999px;

  --spacing-0: 0rem;
  --spacing-0\.5: 0.125rem;
  --spacing-1: 0.25rem;
  --spacing-1\.5: 0.375rem;
  --spacing-2: 0.5rem;
  --spacing-2\.5: 0.625rem;
  --spacing-3: 0.75rem;
  --spacing-3\.5: 0.875rem;
  --spacing-4: 1rem;
  --spacing-4\.5: 1.125rem;
  --spacing-5: 1.25rem;
  --spacing-6: 1.5rem;
  --spacing-7: 1.75rem;
  --spacing-8: 2rem;
  --spacing-9: 2.25rem;
  --spacing-10: 2.5rem;
  --spacing-11: 2.75rem;
  --spacing-12: 3rem;
  --spacing-14: 3.5rem;
  --spacing-16: 4rem;
  --spacing-20: 5rem;
  --spacing-24: 6rem;
  --spacing-28: 7rem;
  --spacing-32: 8rem;
  --spacing-36: 9rem;
  --spacing-40: 10rem;
  --spacing-44: 11rem;
  --spacing-48: 12rem;
  --spacing-52: 13rem;
  --spacing-56: 14rem;
  --spacing-60: 15rem;
  --spacing-64: 16rem;
  --spacing-72: 18rem;
  --spacing-80: 20rem;
  --spacing-96: 24rem;

  --z-index-0: 0;
  --z-index-10: 10;
  --z-index-20: 20;
  --z-index-30: 30;
  --z-index-40: 40;
  --z-index-50: 50;
  --z-index-60: 60;

  --transition-ease-sm: all 0.2s ease-in-out;
  --transition: 0.2s ease-in-out;

  /* Main resolutions used (chromebook)
    1536 x 864, standaard resolutie.
    1162 x 656, kleiner scherm resolutie.  */
  --sidebar-width: 220px;
  --sidebar-width-minimized: 60px;
  --sidebar-transition: 0.2s cubic-bezier(0.47, 1.2, 0.41, 0.8);
  --topbar-height: 71px;
  --margin-layout: clamp(18px, 2.5vw, 40px);
  --page-max-width: 1400px; /* standaard resolutie + sidebar minimized: full width */
}

html,
body {
  font-family: var(--font-sans);
  color: var(--color-neutral-800);
  background-color: var(--color-background);
}

a {
  color: inherit;
  text-decoration: none;
}

.visually-hidden {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.react-tiny-popover-container {
  z-index: var(--z-index-50);
}

.react-select__menu {
  z-index: var(--z-index-50) !important;
}

.react-select__indicator-separator {
  display: none;
}

@keyframes slideDownAndFadeIn {
  from {
    opacity: 0;
    transform: translateY(-4px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes slideDownAndFadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(4px);
  }
}
@keyframes slideLeftAndFadeIn {
  from {
    opacity: 0;
    transform: translateX(4px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes slideLeftAndFadeOut {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-4px);
  }
}
@keyframes slideUpAndFadeIn {
  from {
    opacity: 0;
    transform: translateY(4px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes slideUpAndFadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-4px);
  }
}
@keyframes slideRightAndFadeIn {
  from {
    opacity: 0;
    transform: translateX(-4px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes slideRightAndFadeOut {
  from {
    opacity: 1;
    transform: translateX(0x);
  }
  to {
    opacity: 0;
    transform: translateX(4px);
  }
}
